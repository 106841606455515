import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdAccount, AdAccountWithBusiness } from '@core/models/interfaces/facebook/integration';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { OverlayModule } from 'primeng/overlay';
import { EmptyComponent } from '../empty/empty.component';
import { SearchInputComponent } from '../search-input/search-input.component';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-ad-account-select',
  standalone: true,
  imports: [OverlayModule, CheckboxModule, FormsModule, SearchInputComponent, EmptyComponent, TranslateModule],
  templateUrl: './ad-account-select.component.html',
  styleUrl: './ad-account-select.component.scss'
})
export class AdAccountSelectComponent implements OnChanges {
  @Input() accountBusinesses: AdAccountWithBusiness[] = [];
  @Input() selectType: 'SINGLE' | 'MULTIPLE' = 'SINGLE';
  @Input() adAccountSelected: AdAccount;
  @Input() adAccountsSelected: AdAccount[] = [];

  @Output() adAccountsSelectedChange = new EventEmitter<AdAccount[]>();
  @Output() adAccountSelectedChange = new EventEmitter<AdAccount>();

  readonly prefixAdSelect = 'ad-account-select.';

  isAllChecked: boolean = false;
  isAccount: boolean = false;
  accountBusinessesDisplay: AdAccountWithBusiness[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['accountBusinesses']?.currentValue) {
      this.accountBusinessesDisplay = [...this.accountBusinesses];
    }
  }

  onSelectAdAccount(adAccount: AdAccount) {
    if (this.selectType === 'MULTIPLE') return;
    this.adAccountSelected = adAccount;
    this.adAccountSelectedChange.emit(this.adAccountSelected);
    this.isAccount = false;
    sessionStorage.setItem('adAccountInit', JSON.stringify(adAccount));
  }

  onSearchAdAccounts(query: string) {
    const business = this.accountBusinesses
      .map(business => {
        const matchedAccounts = business.act_account.filter(
          account => account.id.includes(query) || account.name.toLowerCase().includes(query.toLowerCase())
        );

        return matchedAccounts.length > 0 ? { ...business, act_account: matchedAccounts } : null;
      })
      .filter(business => business !== null) as AdAccountWithBusiness[];

    this.accountBusinessesDisplay = [...business];
  }

  onSelectAll(event: CheckboxChangeEvent) {
    if (event.checked) {
      this.isAllChecked = true;
      this.adAccountsSelected = [...this.accountBusinesses]
        .flatMap(item => item.act_account)
        .map(item => ({
          id: item.id,
          name: item.name
        }));
    } else {
      this.isAllChecked = false;
      this.adAccountsSelected = [];
    }
    this.adAccountsSelectedChange.emit(this.adAccountsSelected);
  }

  onCheckAdAccount() {
    const isAllCheck = this.accountBusinesses
      .flatMap(item => item.act_account)
      .every(adAccount => this.adAccountsSelected.map(item => item.id).includes(adAccount.id));

    this.isAllChecked = isAllCheck;
    this.adAccountsSelectedChange.emit(this.adAccountsSelected);
  }
}
