<div>
  <div class="h-7 px-3 flex items-center rounded-full border cursor-pointer bg-white" (click)="isAccount = !isAccount">
    <div class="truncate">
      @if (selectType === 'SINGLE') {
        {{ adAccountSelected?.name }} - ({{ adAccountSelected?.id }})
      } @else {
        @if (adAccountsSelected?.length === 1) {
          {{ adAccountsSelected?.[0]?.name }} - ({{ adAccountsSelected?.[0]?.id }})
        } @else {
          {{ adAccountsSelected?.length }} {{ prefixAdSelect + 'ad-accounts-selected' | translate }}
        }
      }
    </div>
    <i class="icon-wl-arrow-drop-down text-6"></i>
  </div>
  <p-overlay [(visible)]="isAccount" appendTo="body">
    <div class="px-1 py-2 rounded-sm border bg-white shadow-md mt-0.5">
      <div class="px-2">
        <app-search-input
          inputClass="h-10"
          [debounceTime]="300"
          [placeholder]="prefixAdSelect + 'search-by-id-or-name' | translate"
          (onSearch)="onSearchAdAccounts($event)"></app-search-input>
      </div>

      <div class="max-h-[550px] overflow-y-auto">
        @if (accountBusinessesDisplay.length > 0) {
          <div class="h-9 flex items-center pl-2 gap-2">
            @if (selectType === 'MULTIPLE') {
              <p-checkbox [binary]="true" [(ngModel)]="isAllChecked" (onChange)="onSelectAll($event)"></p-checkbox>
            }
            <span>{{ prefixAdSelect + 'all-ad-accounts' | translate }}</span>
          </div>
          @for (business of accountBusinessesDisplay; track business.id) {
            <div>
              <div class="text-2 h-8 flex items-center px-2 font-medium text-gray-600">{{ business.name }}</div>
              <div class="flex flex-col gap-2">
                @for (account of business.act_account; track account.id) {
                  <div
                    class="p-2 flex items-center gap-2 cursor-pointer hover:bg-gray-100 rounded-sm"
                    (click)="onSelectAdAccount(account)">
                    @if (selectType === 'SINGLE') {
                      <div class="h-5 w-5 flex items-center justify-center">
                        @if (account.id === adAccountSelected?.id) {
                          <i class="icon-wl-check text-6 text-primary-600"></i>
                        }
                      </div>
                    } @else {
                      <p-checkbox
                        [(ngModel)]="adAccountsSelected"
                        [value]="{
                          id: account.id,
                          name: account.name
                        }"
                        (onChange)="onCheckAdAccount()"></p-checkbox>
                    }

                    <div class="flex flex-col gap-0.5">
                      <span class="w-max">{{ account.name }}</span>
                      <span class="text-2 text-gray-600"
                        >{{ prefixAdSelect + 'ad-account-id' | translate }}: {{ account.id }}</span
                      >
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        } @else {
          <app-empty styleClass="min-h-[unset] pt-8" size="xs"></app-empty>
        }
      </div>
    </div>
  </p-overlay>
</div>
